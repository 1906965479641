import { faBook } from '@fortawesome/pro-regular-svg-icons';

/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
// eslint-disable-next-line no-underscore-dangle
const getEnv = (key) => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

export const setCurrentVersion = (version = 1) => `v${version}`;

/**
 * Formate la chaîne de version selon le format standardisé: vX.Y.Z
 * Supprime tout préfixe de nom de projet de la chaîne de version
 * @param {string} versionString - La chaîne de version brute de l'environnement
 * @returns {string} - Chaîne de version formatée
 */
export const formatVersion = (versionString) => {
  if (versionString.includes('-frontend.') || versionString.includes('.')) {
    const versionMatch = versionString.match(/(\d+\.\d+\.\d+)/);
    if (versionMatch && versionMatch[1]) {
      return `v${versionMatch[1]}`;
    }
  }
  return versionString.startsWith('v') ? versionString : `v${versionString}`;
};

export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_URL = getEnv('API_URL');
export const APP_URL = API_URL.substring(0, API_URL.indexOf('api'));
export const API_URL_DATATECNEA = getEnv('API_URL_DATATECNEA');
export const API_URL_DOCUMENTS = `${API_URL_DATATECNEA}/${setCurrentVersion(2)}/documents/`;
export const URL_DATATECNEA = getEnv('URL_DATATECNEA');

export const APP_PROFILE = getEnv('PROFILE');
export const APP_CONTACT_NAME = APP_PROFILE === 'AFF'
    ? "l'AFF"
    : 'le groupe TECNEA';
export const APP_CONTACT_MAIL = APP_PROFILE === 'AFF'
    ? 'secretariat-a.f.f@wanadoo.fr'
    : 'formation@cemafroid.fr';

export const KEYCLOAK_REALM = getEnv('KEYCLOAK_REALM');
export const KEYCLOAK_AUTH_SERVER_URL = getEnv('KEYCLOAK_AUTH_SERVER_URL');
export const KEYCLOAK_RESOURCE = getEnv('KEYCLOAK_RESOURCE');

export const RGPD_EMAIL = 'rgpd@tecnea.fr';
export const ASSISTANCE_EMAIL = 'formation@cemafroid.fr';
export const ASSISTANCE_EMAIL_AFF = 'secretariat-a.f.f@wanadoo.fr';

export const APP_VERSION = formatVersion(getEnv('VERSION'));
export const ENVIRONMENT = getEnv('ENVIRONMENT');
export const CREATED = getEnv('CREATED');
export const REVISION = getEnv('REVISION');
export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');

export const LIST_SIZE = 20;
export const MAX_FILE_SIZE = 4000000; // in Bytes = 4mo

export const PATHS = {
  ADMINISTRATION: `${ROOT_PATH}administration`,
  ADMIN_DOCUMENT: `${ROOT_PATH}administration/documents`,
  ADMIN_SEQUENCE: `${ROOT_PATH}administration/sequences`,
  ADMIN_MODULE: `${ROOT_PATH}administration/modules`,
  TRAINEES: `${ROOT_PATH}stagiaires/:content/:contentId`,
  PROFILE: `${ROOT_PATH}profil/:userId`,
  HOME: `${ROOT_PATH}accueil`,
  HOME_ECLER: `${ROOT_PATH}accueil/1`,
  CATALOG: `${ROOT_PATH}catalog`,
  CONTENT: `${ROOT_PATH}contenu`,
  PROGRAM: `${ROOT_PATH}programmes/:programId`,
  PROGRAMS_CREATE: `${ROOT_PATH}programmes/creation`,
  PROGRAM_MODULE: `${ROOT_PATH}programmes/:programId/modules/:moduleId`,
  PROGRAM_MODULE_DETAIL: `${ROOT_PATH}programmes/:programId/modules/:moduleId/detail`,
  MODULE: `${ROOT_PATH}modules/:moduleId`,
  MODULE_DETAIL: `${ROOT_PATH}modules/:moduleId/detail`,
  MODULES_CREATE: `${ROOT_PATH}modules/creation`,
  PROGRAM_MODULE_SEQUENCE: `${ROOT_PATH}programmes/:programId/modules/:moduleId/sequences/:sequenceId`,
  MODULE_SEQUENCE: `${ROOT_PATH}modules/:moduleId/sequences/:sequenceId`,
  SEQUENCE: `${ROOT_PATH}sequences/:sequenceId`,
  SEQUENCE_CREATE: `${ROOT_PATH}sequences/creation`,
  SEQUENCE_FROM_QR_CODE: `${ROOT_PATH}ecler/:name`,
  PROGRAM_FROM_QR_CODE: `${ROOT_PATH}ecler`,
  RGPD: '/mentions-legales',
  COOKIES: '/gestion-cookies',
  QUIZ: `${ROOT_PATH}quiz/:quizId`,
  SEQUENCE_QUIZ: `${ROOT_PATH}sequences/:sequenceId/quiz/:quizId`,
  MODULE_SEQUENCE_QUIZ: `${ROOT_PATH}modules/:moduleId/sequences/:sequenceId/quiz/:quizId`,
  PROGRAM_MODULE_SEQUENCE_QUIZ: `${ROOT_PATH}programmes/:programId/modules/:moduleId/sequences/:sequenceId/quiz/:quizId`
};

export const ROUTES = {
  ...PATHS,
  PROGRAM: (programId) => `${ROOT_PATH}programmes/${programId}`,
  PROGRAM_MODULE: (programId, moduleId) => `${ROOT_PATH}programmes/${programId}/modules/${moduleId}`,
  PROGRAM_MODULE_DETAIL: (programId, moduleId) => `${ROOT_PATH}programmes/${programId}/modules/${moduleId}/detail`,
  MODULE: (moduleId) => `${ROOT_PATH}modules/${moduleId}`,
  MODULE_DETAIL: (moduleId) => `${ROOT_PATH}modules/${moduleId}/detail`,
  PROGRAM_MODULE_SEQUENCE: (programId, moduleId, sequenceId) => `${ROOT_PATH}programmes/${programId}/modules/${moduleId}/sequences/${sequenceId}`,
  MODULE_SEQUENCE: (moduleId, sequenceId) => `${ROOT_PATH}modules/${moduleId}/sequences/${sequenceId}`,
  SEQUENCE: (sequenceId) => `${ROOT_PATH}sequences/${sequenceId}`,
  TRAINEES: (content, contentId) => `${ROOT_PATH}stagiaires/${content}/${contentId}`,
  QUIZ: (quizId) => `${ROOT_PATH}quiz/${quizId}`,
  SEQUENCE_QUIZ: (sequenceId, quizId) => `${ROOT_PATH}sequences/${sequenceId}/quiz/${quizId}`,
  MODULE_SEQUENCE_QUIZ: (moduleId, sequenceId, quizId) => `${ROOT_PATH}modules/${moduleId}/sequences/${sequenceId}/quiz/${quizId}`,
  PROGRAM_MODULE_SEQUENCE_QUIZ: (programId, moduleId, sequenceId, quizId) => `${ROOT_PATH}programmes/${programId}/modules/${moduleId}/sequences/${sequenceId}/quiz/${quizId}`,
  PROFILE: (userId) => `${ROOT_PATH}profil/${userId}`
};

export const API_ROUTES = {
  TRANSLATIONS: (locale) => `${API_URL}/${setCurrentVersion()}/translations/front-labels?locale=${locale}`,

  DOCUMENT: (documentId) => `${API_URL_DOCUMENTS}${documentId}`,
  DOCUMENTS: API_URL_DOCUMENTS,
  DOCUMENT_DETAILS: (documentId) => `${API_URL_DOCUMENTS}${documentId}/details?loadContent=false`,
  DOCUMENT_CREATE: () => API_URL_DOCUMENTS,
  DOCUMENT_UPDATE: (documentId) => `${API_URL_DOCUMENTS}${documentId}`,
  DOCUMENTS_FIND: ({
                     search, page, size, filters
                   }) => `${API_URL_DOCUMENTS}?search=${search}&page=${page}&size=${size}${filters}`,

  PROGRAMS: ({ search = '', filters = '' }) => `${API_URL}/${setCurrentVersion()}/programs?search=${search}${filters}`,
  PROGRAM: (programId) => `${API_URL}/${setCurrentVersion()}/programs/${programId}`,
  PROGRAM_UPDATE: (programId) => `${API_URL}/${setCurrentVersion()}/programs/${programId}`,
  PROGRAM_DELETE: (programId) => `${API_URL}/${setCurrentVersion()}/programs/${programId}`,

  MODULES: ({
              programId = '', search = '', filters = '', page, size
            }) => `${API_URL}/${setCurrentVersion()}/modules?programId=${programId}&search=${search}&page=${page}&size=${size}${filters}`,
  MODULE: (programId = '', moduleId = '') => `${API_URL}/${setCurrentVersion()}/modules/${moduleId}?programId=${programId}`,
  MODULE_UPDATE: (moduleId) => `${API_URL}/${setCurrentVersion()}/modules/${moduleId}`,
  MODULE_DELETE: (moduleId) => `${API_URL}/${setCurrentVersion()}/modules/${moduleId}`,
  MODULE_THEMES: () => `${API_URL}/${setCurrentVersion()}/themes`,

  SEQUENCES: (moduleId = '') => `${API_URL}/${setCurrentVersion()}/sequences?moduleId=${moduleId}`,
  SEQUENCE: (programId = '', moduleId = '', sequenceId = '') => `${API_URL}/${setCurrentVersion()}/sequences/${sequenceId}?moduleId=${moduleId}&programId=${programId}`,
  SEQUENCE_COPY: (programId = '', moduleId = '', sequenceId = '') => `${API_URL}/${setCurrentVersion()}/sequences/${sequenceId}/copy?moduleId=${moduleId}&programId=${programId}`,
  SEQUENCE_UPDATE: (sequenceId) => `${API_URL}/${setCurrentVersion()}/sequences/${sequenceId}`,
  SEQUENCE_DELETE: (sequenceId) => `${API_URL}/${setCurrentVersion()}/sequences/${sequenceId}`,
  SEQUENCES_FIND: ({
                     search, page, size, filters
                   }) => `${API_URL}/${setCurrentVersion()}/sequences?search=${search}&page=${page}&size=${size}${filters}`,

  SESSIONS: ({ moduleId, fromDate, toDate }) => `${API_URL}/${setCurrentVersion()}/sessions?moduleId=${moduleId}&fromDate=${fromDate}&toDate=${toDate}`,
  SESSION_CREATE: () => `${API_URL}/${setCurrentVersion()}/sessions`,
  SESSION: (sessionId) => `${API_URL}/sessions/${setCurrentVersion()}/${sessionId}`,
  SESSION_UPDATE: (sessionId) => `${API_URL}/${setCurrentVersion()}/sessions/${sessionId}`,
  SESSION_DELETE: (sessionId) => `${API_URL}/${setCurrentVersion()}/sessions/${sessionId}`,

  REFERENCE_DATA: () => `${API_URL_DATATECNEA}/${setCurrentVersion(2)}/data-types?application=DATAFORM`,
  ACCESS_MODES: () => `${API_URL}/${setCurrentVersion()}/sequences/access-modes`,
  HEALTH_CHECK: `${API_URL}/${setCurrentVersion()}/healthcheck`,
  PING: `${API_URL}/${setCurrentVersion()}/ping`,

  SUBSCRIPTION_CREATE: () => `${API_URL}/${setCurrentVersion()}/access-requests`,
  SUBSCRIPTION_IMPORT: ({ programId, moduleId }) => `${API_URL}/${setCurrentVersion()}/access-requests/import?programId=${programId}&moduleId=${moduleId}`,
  SUBSCRIPTION_UPDATE: (id) => `${API_URL}/${setCurrentVersion()}/subscriptions/dates/${id}`,
  SUBSCRIPTION_DELETE: (id) => `${API_URL}/${setCurrentVersion()}/subscriptions/${id}`,
  SUBSCRIPTION_TRAINEES: (search) => `${API_URL}/${setCurrentVersion()}/trainees?page=0&size=20&freeSearch=${search}`,
  SUBSCRIPTION_TRAINEES_EXPORT: ({ programId, moduleId }) => `${API_URL}/${setCurrentVersion()}/trainees/mail-notif-export?programId=${programId}&moduleId=${moduleId}`,
  TRAINEES_LIST_PROGRAM: ({ contentId, page }) => `${API_URL}/${setCurrentVersion()}/trainees/program/${contentId}?page=${page}&size=20`,
  TRAINEES_LIST_MODULE: ({ contentId, page }) => `${API_URL}/${setCurrentVersion()}/trainees/module/${contentId}?page=${page}&size=20`,

  UPDATE_LOCALE: (locale) => `${API_URL}/${setCurrentVersion()}/user-accounts/locale?locale=${locale}`,
  USER_ACCOUNTS_DETAIL: (personId) => `${API_URL}/${setCurrentVersion()}/user-accounts/${personId}`,
  RESET_PASSWORD: () => `${API_URL}/${setCurrentVersion()}/user-accounts/resetPassword`,

  GET_LANGUAGE_LIST: () => `${API_URL}/${setCurrentVersion()}/application-languages`
};

export const APPLICATION_ROLES = {
  TRAINEE: 'ROLE_DATAFORM_TRAINEE',
  ADMIN: 'ROLE_DATAFORM_ADMIN',
  SUPER_ADMIN: 'ROLE_DATAFORM_SUPER_ADMIN',
  COMPANY_MANAGER: 'ROLE_DATAFORM_COMPANY_MANAGER',
  CONTENT_MANAGER: 'ROLE_DATAFORM_CONTENT_MANAGER',
  INSTRUCTOR: 'ROLE_DATAFORM_INSTRUCTOR'
};

export const ROLE_GROUPS = {
  ADMINISTRATORS: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN],
  CONTENT_EDITORS: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
    APPLICATION_ROLES.CONTENT_MANAGER],
  CONTENT_VIEWERS: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
    APPLICATION_ROLES.CONTENT_MANAGER, APPLICATION_ROLES.INSTRUCTOR,
    APPLICATION_ROLES.TRAINEE]
};

export const MENU_ITEMS = [{
  title: 'menu.catalog',
  icon: faBook,
  url: ROUTES.CATALOG
}
  // , {
  //   title: 'menu.content',
  //   icon: faBookUser,
  //   url: ROUTES.CONTENT
  // }
];

export const EDITION_PANEL_TYPES = {
  PROGRAM: 'program',
  MODULE: 'module',
  SEQUENCE: 'sequence',
  SECTION: 'section',
  ELEMENT: 'element'
};

export const REF_DATA_MAP = {
  accessRights: 'ACCESS_RIGHTS',
  copyright: 'COPYRIGHT',
  location: 'LOCATION'
};

export const REF_DATA_MULTIPLE_MAP = {
  ACKNOWLEDGEMENT: true,
  PUBLIC_EXPERIENCE: true,
  PUBLIC_LEVEL: true,
  PUBLIC_TYPE: true,
  KEYWORD: true,
  HANDICAP: true,
  ACCESS_RIGHTS: false,
  COPYRIGHT: false,
  DOMAIN: false,
  INTRA_INTER: false,
  LOCATION: false,
  LOCATIONS: true,
  REVIEW_TYPE: true,
  MODALITY: false,
  NATURE: false,
  TRAINING_TYPE: false,
  TRAINING_CATEGORY: false
};

export const MODULE_VALIDATION_MAP = {
  name: { type: 'REQUIRED' },
  code: { type: 'REQUIRED' },
  reference: { type: 'REQUIRED' },
  theme: { type: 'REQUIRED' },
  modality: { type: 'REQUIRED' },
  trainingCategory: { type: 'REQUIRED' },
  trainingType: { type: 'REQUIRED' },
  domain: { type: 'REQUIRED' },
  numberOfDays: { type: 'REQUIRED' },
  keywords: { type: 'REQUIRED' },
  professionalBenefits: { type: 'REQUIRED' },
  locations: { type: 'REQUIRED' },
  intraInter: { type: 'REQUIRED' },
  fareCodeForIntra: { type: 'REQUIRED' },
  fareCodeForInter: { type: 'REQUIRED' },
  pedagogicalResources: { type: 'REQUIRED' },
  acknowledgements: { type: 'REQUIRED' },
  reviewTypes: { type: 'REQUIRED' },
  publicTypes: { type: 'REQUIRED' },
  publicLevels: { type: 'REQUIRED' },
  publicExperiences: { type: 'REQUIRED' }
};

export const PROGRAM_VALIDATION_MAP = {
  name: { type: 'REQUIRED' },
  code: { type: 'REQUIRED' },
  description: { type: 'REQUIRED' }
};

export const PROGRAMS_UID = {
  ecler: '2033413a-d97f-4c4f-9eaf-356cae850f5a'
};

export const MODULES_UID = {
  'good-practice': 'a2ac368e-e839-420d-b1d3-730e48cbabb4'
};

export const SEQUENCES_UID = {
  porte: '163c6261-fe6a-43ea-b98a-57077bc35c60',
  rideau: '6797f517-9d2c-4586-9ead-d6509e792e95',
  ombre: '1af2f1b9-4a3d-4456-931e-e582ece55494',
  'coupure-groupe': '8c5b2448-eac5-4690-882f-8760131cca1d',
  'circulation-air': '59503a80-60d8-4afa-828a-f1feab295a04',
  temperature: '25559a65-7308-4cbc-988c-e6854d084bc3'
};

// eslint-disable-next-line max-len
export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;